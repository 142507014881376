import { gql } from 'graphql-request';

import { MessageID, MessageUUID } from '../messagesTypes';

export interface CreateMessageWithTaskNanoIdQueryResponse {
  id: MessageID;
  uuid: MessageUUID;
}

export const CREATE_MESSAGE_WITH_TASK_NANO_ID = gql`
  mutation CreateMessageWithTaskNanoId(
    $attachedFileAttachmentIds: [ID!]
    $body: String!
    $colors: [String!]
    $fileAttachmentId: ID
    $fileAttachmentIds: [ID!]
    $flagged: Boolean
    $mentionedUserIds: [ID!]
    $messageType: String
    $muted: Boolean
    $mutedForClientsAt: DateTime
    $propagate: Boolean
    $red: Boolean
    $selectedLifestyleIds: [ID!]
    $selectedMaterialIds: [ID!]
    $selectedProductIds: [ID!]
    $taskNanoId: NanoId!
    $threadKeyId: String
    $threadStart: Boolean
    $visibleForClient: Boolean
    $whiteboardKeyId: String
    $repliedMessageId: ID
  ) {
    createMessageWithTaskNanoId(
      input: {
        attachedFileAttachmentIds: $attachedFileAttachmentIds
        body: $body
        colors: $colors
        fileAttachmentId: $fileAttachmentId
        fileAttachmentIds: $fileAttachmentIds
        flagged: $flagged
        mentionedUserIds: $mentionedUserIds
        messageType: $messageType
        muted: $muted
        mutedForClientsAt: $mutedForClientsAt
        propagate: $propagate
        red: $red
        selectedLifestyleIds: $selectedLifestyleIds
        selectedMaterialIds: $selectedMaterialIds
        selectedProductIds: $selectedProductIds
        taskNanoId: $taskNanoId
        threadKeyId: $threadKeyId
        threadStart: $threadStart
        visibleForClient: $visibleForClient
        whiteboardKeyId: $whiteboardKeyId
        repliedMessageId: $repliedMessageId
      }
    ) {
      status
      recordUuid
      record {
        id
        uuid
      }
      errors {
        attachedFileAttachmentIds
        body
        colors
        fileAttachmentIds
        flagged
        fullMessages
        propagate
        red
        selectedLifestyleIds
        selectedMaterialIds
        selectedProductIds
        taskId
        visibleForClient
      }
    }
  }
`;
