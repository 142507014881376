import React from 'react';

import { Icon } from '../Icon';

import { SizeHelper } from '../SizeHelper';

import { IconsEnum } from '../../assets/icons/types';
import {
  FileAttachmentName,
  FileAttachmentSize,
  FileAttachmentSrc
} from './FileAttachmentHelper.types';

import { Files } from '../../utils/Files';

const getExtIcon = (src: FileAttachmentSrc) => {
  if (Files.isTableFile(src)) return IconsEnum.DOCUMENT_TABLE_OUTLINE;
  if (Files.isTextFile(src)) return IconsEnum.DOCUMENT_TEXT_OUTLINE;
  return IconsEnum.DOCUMENT_OUTLINE;
};

interface FileAttachmentHelperProps {
  name: FileAttachmentName;
  size: FileAttachmentSize;
  src: FileAttachmentSrc;
}

function FileAttachmentHelper({ size, name, src }: FileAttachmentHelperProps) {
  return (
    <div className="flex flex-col gap-4 items-center justify-center h-full w-full border dark:border-gray-700 rounded-md p-4">
      <div className="text-gray-400 dark:text-gray-500 flex justify-center">
        <Icon icon={getExtIcon(src)} className="h-12 w-12" strokeWidth="1" />
      </div>
      <div className="text-center w-full space-y-2">
        <div className="line-clamp-3 text-md text-current break-words leading-snug">
          {name}
        </div>
        <div className="text-xs text-gray-400 dark:text-gray-500">
          <SizeHelper size={size} />
        </div>
      </div>
    </div>
  );
}

export default FileAttachmentHelper;
