import {
  SendResultsToClientInTaskGqlQuery,
  SendResultsToClientInTaskGqlStatus,
  TaskGqlError,
  TaskNanoID,
  TaskUUID
} from '../../tasksTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface SendResultsToClientInTaskOptions {
  query: SendResultsToClientInTaskGqlQuery;
}

export interface SendResultsToClientInTaskResponse<
  SendResultsToClientInTaskRecordType
> {
  sendResultsToClientInTask: {
    status: SendResultsToClientInTaskGqlStatus;
    recordUuid: TaskUUID;
    recordNanoId: TaskNanoID;
    record: SendResultsToClientInTaskRecordType;
    errors: SendResultsToClientInTaskError;
  };
}

export interface SendResultsToClientInTaskInput {
  uuid: TaskUUID | TaskNanoID;
}

export interface SendResultsToClientInTaskError {
  fullMessages: TaskGqlError;
}

const action = 'sendResultsToClientInTask';

const scope = 'task';

const pluralScope = 'tasks';

function useSendResultsToClientInTask<SendResultsToClientInTaskRecordType>({
  query
}: SendResultsToClientInTaskOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    SendResultsToClientInTaskInput,
    SendResultsToClientInTaskResponse<SendResultsToClientInTaskRecordType>,
    SendResultsToClientInTaskError,
    SendResultsToClientInTaskRecordType
  >({ action, pluralScope, scope, query });

  return {
    sendResultsToClientInTaskData: updateQueryData,
    sendResultsToClientInTaskError: updateQueryError,
    sendResultsToClientInTaskLoading: updateQueryLoading,
    sendResultsToClientInTaskErrorMessage: updateQueryErrorMessage,
    sendResultsToClientInTask: updateQuery,
    sendResultsToClientInTaskReset: updateQueryReset
  };
}

export default useSendResultsToClientInTask;
