import React from 'react';
import { Control, Controller, FieldPath } from 'react-hook-form';

import { ClassName, IsDisabled } from '../../../../../../../types';

import { Checkbox } from '../../../../../../../helpers/Checkbox';

import { tasksKeys } from '../../../../../../../locales/keys';

export interface FinalResultFieldProps<T> {
  control: Control<T>;
  name: FieldPath<T>;
  disabled?: IsDisabled;
  labelClassName?: ClassName;
}

function FinalResultField<T>({
  control,
  name,
  disabled,
  labelClassName
}: FinalResultFieldProps<T>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <Checkbox
          id={name}
          disabled={disabled}
          i18nLabel={tasksKeys.finalResult}
          checkboxWrapperClassName="flex items-center justify-center"
          labelClassName={labelClassName}
          onChange={onChange}
          name={name}
          checked={!!value}
        />
      )}
    />
  );
}

export default FinalResultField;
