import { gql } from 'graphql-request';

import { TaskNanoID, TaskUUID } from '../tasksTypes';

export interface AcceptResultsInTaskQueryResponse {
  uuid: TaskUUID;
  nanoId: TaskNanoID;
}

export const ACCEPT_RESULTS_IN_TASK_QUERY = gql`
  mutation AcceptResultsInTask(
    $uuid: ID!
    $mark: Integer!
    $feedback: String
    $withoutAccept: Boolean
    $withoutClose: Boolean
    $async: Boolean
  ) {
    acceptResultsInTask(
      input: {
        uuid: $uuid
        mark: $mark
        feedback: $feedback
        withoutAccept: $withoutAccept
        withoutClose: $withoutClose
        async: $async
      }
    ) {
      recordUuid
      recordNanoId
      status
      record {
        uuid
        nanoId
      }
      errors {
        fullMessages
      }
    }
  }
`;
