import { Translate } from '../../../../../../../helpers/Translate';

import { stringsKeys } from '../../../../../../../locales/keys';

function AcceptTasksResultsWarning() {
  return (
    <div className="p-4">
      <p className="mb-4">
        <Translate
          id={stringsKeys.beforeYouAcceptTheTaskWeLikeToRemindYouThat}
        />
      </p>
      <ul className="pl-4 list-decimal space-y-2">
        <li>
          <Translate
            id={
              stringsKeys.onceYouAcceptTheTaskItWillCloseAndYouWillNotBeAbleToLeaveNewCommentsInIt
            }
          />
        </li>
        <li>
          <Translate
            id={
              stringsKeys.ifYouWantTheResultTweakedOrAdjustedAfterThatItWillBeANewTaskAndChargedAccordingly
            }
          />
        </li>
        <li>
          <Translate
            id={
              stringsKeys.youCanAlwaysDownloadTheFilesToSeeThemOnYourDeviceBeforeAcceptingTheTask
            }
          />
        </li>
      </ul>
    </div>
  );
}

export default AcceptTasksResultsWarning;
