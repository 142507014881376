import { gql } from 'graphql-request';

import { TaskNanoID, TaskUUID } from '../tasksTypes';

export interface DownloadTaskResultsQueryResponse {
  uuid: TaskUUID;
  nanoId: TaskNanoID;
}

export const DOWNLOAD_TASK_RESULTS_QUERY = gql`
  mutation DownloadTaskResults($uuid: ID!, $deviceNanoId: String!) {
    downloadTaskResults(input: { uuid: $uuid, deviceNanoId: $deviceNanoId }) {
      recordUuid
      recordNanoId
      status
      record {
        uuid
        nanoId
      }
      errors {
        fullMessages
      }
    }
  }
`;
