import { gql } from 'graphql-request';

import { TaskNanoID, TaskUUID } from '../tasksTypes';

export interface DownloadTaskSourceFilesQueryResponse {
  uuid: TaskUUID;
  nanoId: TaskNanoID;
}

export const DOWNLOAD_TASK_SOURCE_FILES_QUERY = gql`
  mutation DownloadTaskSourceFiles($uuid: ID!, $deviceNanoId: String!) {
    downloadTaskSourceFiles(
      input: { uuid: $uuid, deviceNanoId: $deviceNanoId }
    ) {
      recordUuid
      recordNanoId
      status
      record {
        uuid
        nanoId
      }
      errors {
        fullMessages
      }
    }
  }
`;
