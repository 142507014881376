import React, { ReactNode } from 'react';
import { Control } from 'react-hook-form';

import { ErrorMessage, FormID, IsLoading } from '../../../../../types';
import { AcceptResultsInTaskFormData } from './AcceptResultsInTaskForm.types';
import { TaskAcceptResultsFields } from '../../../tasksTypes';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { Form } from '../../../../../helpers/Form';
import { StarsRadioButtonsField } from '../../../../../helpers/FormFields/StarsRadioButtonsField';
import { CheckPermissions } from '../../../../../helpers/CheckPermissions';

import { TasksPermissions } from '../../../tasksConstants';

interface AcceptResultsInTaskFormProps {
  form: FormID;
  control: Control<AcceptResultsInTaskFormData>;
  isLoading: IsLoading;
  markValidationError: ErrorMessage;
  footer?: ReactNode;
  isRFA?: boolean;
  acceptResultsWarning?: ReactNode;
}

function AcceptResultsInTaskForm({
  form,
  control,
  isLoading,
  markValidationError,
  footer,
  isRFA,
  acceptResultsWarning
}: AcceptResultsInTaskFormProps) {
  const starsRadioButtonsId = `${form}-star-radio-button`;

  return (
    <Form className="flex flex-col gap-4 justify-center items-center" id={form}>
      {isRFA ? (
        <CheckPermissions
          action={TasksPermissions.READ_ACCEPT_RESULTS_IN_TASK_BUTTON}
        >
          {acceptResultsWarning}
          <div className="flex justify-center">
            <StarsRadioButtonsField
              amount={5}
              name={TaskAcceptResultsFields.MARK}
              control={control}
              disabled={isLoading}
              iconClassName="w-8 h-8"
              id={starsRadioButtonsId}
            />
          </div>
        </CheckPermissions>
      ) : null}

      <AlertMessage i18nMessage={markValidationError} />
      {footer}
    </Form>
  );
}

export default AcceptResultsInTaskForm;
