import React, { useCallback } from 'react';

import { I18nText } from '../../../../../types';

import { TaskNanoID } from '../../../tasksTypes';
import { IconsEnum } from '../../../../../assets/icons/types';

import {
  DOWNLOAD_TASK_SOURCE_FILES_QUERY,
  DownloadTaskSourceFilesQueryResponse
} from '../../../queries/downloadTaskSourceFiles.query';

import { useShowToastOnErrorChange } from '../../../../../common/hooks/useShowToastOnErrorChange';
import { useDownloadNanoId } from '../../../../downloads/hooks/useDownloadNanoId';
import { useDownloadTaskSourceFiles } from '../../../hooks/useDownloadTaskSourceFiles';

import { PureTooltipIconButtonHelper } from '../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import { sourceFilesKeys } from '../../../../../locales/keys';

interface DownloadTaskSourceFilesButtonProps {
  taskNanoId: TaskNanoID;
  tooltipI18nText?: I18nText;
}

function DownloadTaskSourceFilesButton({
  taskNanoId,
  tooltipI18nText
}: DownloadTaskSourceFilesButtonProps) {
  const { downloadNanoId } = useDownloadNanoId();

  const {
    downloadTaskSourceFiles,
    downloadTaskSourceFilesErrorMessage,
    downloadTaskSourceFilesLoading
  } = useDownloadTaskSourceFiles<DownloadTaskSourceFilesQueryResponse>({
    query: DOWNLOAD_TASK_SOURCE_FILES_QUERY
  });

  useShowToastOnErrorChange({
    error: downloadTaskSourceFilesErrorMessage
  });

  const handleDownloadTaskResults = useCallback<() => void>(
    () =>
      downloadTaskSourceFiles({
        uuid: taskNanoId,
        deviceNanoId: downloadNanoId
      }),
    [taskNanoId, downloadNanoId, downloadTaskSourceFiles]
  );

  return (
    <PureTooltipIconButtonHelper
      className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
      icon={IconsEnum.DOCUMENT_DOWNLOAD_OUTLINE}
      iconClassName="w-5 h-5 mr-2"
      i18nText={sourceFilesKeys.downloadPlural}
      disabled={downloadTaskSourceFilesLoading}
      onClick={handleDownloadTaskResults}
      tooltipI18nText={tooltipI18nText}
    />
  );
}

export default DownloadTaskSourceFilesButton;
