import { gql } from 'graphql-request';

import { TaskNanoID, TaskUUID } from '../tasksTypes';

export interface ApproveResultsInTaskQueryResponse {
  uuid: TaskUUID;
  nanoId: TaskNanoID;
}

export const APPROVE_RESULTS_IN_TASK_QUERY = gql`
  mutation ApproveResultsInTask(
    $uuid: ID!
    $supportMark: Integer!
    $feedback: String
    $final: Boolean
    $withoutClose: Boolean
  ) {
    approveResultsInTask(
      input: {
        uuid: $uuid
        supportMark: $supportMark
        feedback: $feedback
        final: $final
        withoutClose: $withoutClose
      }
    ) {
      recordUuid
      recordNanoId
      status
      record {
        uuid
        nanoId
      }
      errors {
        fullMessages
      }
    }
  }
`;
