import React, { useCallback } from 'react';

import { ClassName, I18nText } from '../../../../../types';

import { IconsEnum } from '../../../../../assets/icons/types';

import { TaskNanoID } from '../../../tasksTypes';

import {
  SEND_RESULTS_TO_CLIENT_IN_TASK_QUERY,
  SendResultsToClientInTaskQueryResponse
} from '../../../queries/sendResultsToClientInTask.query';

import { useToastNotification } from '../../../../../common/hooks/useToastNotification';
import { useShowToastOnErrorChange } from '../../../../../common/hooks/useShowToastOnErrorChange';

import { useSendResultsToClientInTask } from '../../../hooks/useSendResultsToClientInTask';

import { PureTooltipIconButtonHelper } from '../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import { messagesKeys } from '../../../../../locales/keys';

interface SendResultsToClientInTaskButtonProps {
  taskNanoId: TaskNanoID;
  className?: ClassName;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  i18nText?: I18nText;
  tooltipI18nText?: I18nText;
}

function SendResultsToClientInTaskButton({
  taskNanoId,
  className,
  icon,
  iconClassName,
  i18nText,
  tooltipI18nText
}: SendResultsToClientInTaskButtonProps) {
  const { showToastI18nNotification } = useToastNotification({
    appearance: 'success',
    i18nMessage: messagesKeys.sent
  });

  const {
    sendResultsToClientInTask,
    sendResultsToClientInTaskErrorMessage,
    sendResultsToClientInTaskLoading
  } = useSendResultsToClientInTask<SendResultsToClientInTaskQueryResponse>({
    query: SEND_RESULTS_TO_CLIENT_IN_TASK_QUERY
  });

  useShowToastOnErrorChange({ error: sendResultsToClientInTaskErrorMessage });

  const handleSendResultsToClientInTask = useCallback<() => void>(
    () =>
      sendResultsToClientInTask({ uuid: taskNanoId }).then(() =>
        showToastI18nNotification()
      ),
    [taskNanoId, sendResultsToClientInTask, showToastI18nNotification]
  );

  return (
    <PureTooltipIconButtonHelper
      className={
        className ||
        'py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0'
      }
      icon={icon || IconsEnum.MAIL_OUTLINE}
      iconClassName={iconClassName}
      i18nText={i18nText}
      disabled={sendResultsToClientInTaskLoading}
      onClick={handleSendResultsToClientInTask}
      tooltipI18nText={tooltipI18nText}
    />
  );
}

export default SendResultsToClientInTaskButton;
