import React from 'react';
import cl from 'classnames';
import range from 'lodash/range';

import { IconsEnum } from '../../assets/icons/types';
import { ClassName } from '../../types';

import { StarsFilledAmount, StarsAmount } from './Stars.types';

import { Icon } from '../Icon';

interface StarsProps {
  amount?: StarsAmount;
  filledAmount: StarsFilledAmount;
  starIconClassName?: ClassName;
  containerClassName?: ClassName;
}

function Stars({
  amount = 5,
  filledAmount,
  starIconClassName,
  containerClassName
}: StarsProps) {
  return (
    <div className={containerClassName || 'inline-flex'}>
      {range(1, amount + 1).map((i) => (
        <span
          key={i}
          className={
            i <= filledAmount
              ? 'text-yellow-500'
              : 'text-gray-400 dark:text-gray-600'
          }
        >
          <Icon
            icon={IconsEnum.FILLED_STAR}
            className={cl(starIconClassName || 'h-5 w-5')}
          />
        </span>
      ))}
    </div>
  );
}

export default Stars;
