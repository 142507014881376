import {
  DownloadTaskResultsGqlQuery,
  DownloadTaskResultsGqlStatus,
  TaskDeviceNanoId,
  TaskGqlError,
  TaskNanoID,
  TaskUUID
} from '../../tasksTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface DownloadTaskResultsOptions {
  query: DownloadTaskResultsGqlQuery;
  cacheKeys?: string[];
}

export interface DownloadTaskResultsResponse<DownloadTaskResultsRecordType> {
  downloadTaskResults: {
    status: DownloadTaskResultsGqlStatus;
    recordUuid: TaskUUID;
    recordNanoId: TaskNanoID;
    record: DownloadTaskResultsRecordType;
    errors: DownloadTaskResultsError;
  };
}

export interface DownloadTaskResultsInput {
  uuid: TaskUUID | TaskNanoID;
  deviceNanoId: TaskDeviceNanoId;
}

export interface DownloadTaskResultsError {
  fullMessages: TaskGqlError;
}

const action = 'downloadTaskResults';

function useDownloadTaskResults<DownloadTaskResultsRecordType>({
  query,
  cacheKeys
}: DownloadTaskResultsOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    DownloadTaskResultsInput,
    DownloadTaskResultsResponse<DownloadTaskResultsRecordType>,
    DownloadTaskResultsError,
    DownloadTaskResultsRecordType
  >({ action, query, cacheKeys });

  return {
    downloadTaskResultsData: updateQueryData,
    downloadTaskResultsError: updateQueryError,
    downloadTaskResultsLoading: updateQueryLoading,
    downloadTaskResultsErrorMessage: updateQueryErrorMessage,
    downloadTaskResults: updateQuery,
    downloadTaskResultsReset: updateQueryReset
  };
}

export default useDownloadTaskResults;
