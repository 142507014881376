import cl from 'classnames';
import range from 'lodash/range';

import { ClassName, ErrorMessage } from '../../types';
import {
  StarsRadioButtonsValue,
  StarsRadioButtonsAmount,
  StarsRadioButtonsOnChange
} from './StarsRadioButtons.types';

import { StarsRadioButtonsItem } from './helpers/StarsRadioButtonsItem';
import { Translate } from '../../helpers/Translate';

interface StarsRadioButtonsProps {
  name: string;
  value?: StarsRadioButtonsValue;
  amount?: StarsRadioButtonsAmount;
  disabled?: boolean;
  iconClassName?: ClassName;
  onChange: StarsRadioButtonsOnChange;
  error?: ErrorMessage;
  errorClassName?: ClassName;
  id?: string;
}

function StarsRadioButtons({
  name,
  value,
  amount = 5,
  disabled,
  iconClassName,
  error,
  errorClassName,
  onChange,
  id
}: StarsRadioButtonsProps) {
  return (
    <>
      <div className="inline-flex rating-radio focus-within:ring-2 ring-current rounded">
        {range(0, amount + 1).map((i) => (
          <StarsRadioButtonsItem
            key={i}
            name={name}
            value={value}
            buttonValue={i}
            disabled={disabled}
            iconClassName={iconClassName}
            onChange={onChange}
            id={id}
          />
        ))}
      </div>
      {error && (
        <p className={cl(errorClassName || 'text-sm text-red-600')}>
          {/^forms\.errors+/.test(error) ? <Translate id={error} /> : error}
        </p>
      )}
    </>
  );
}

export default StarsRadioButtons;
