import {
  TaskGqlError,
  TaskUUID,
  TaskSupportMark,
  UpdateTaskGqlQuery,
  UpdateTaskGqlStatus,
  UpdateTaskCacheKeys
} from '../../tasksTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface ApproveResultsInTaskOptions {
  query: UpdateTaskGqlQuery;
  cacheKeys?: UpdateTaskCacheKeys;
}

export interface ApproveResultsInTaskResponse<ApproveResultsInTaskRecordType> {
  approveResultsInTask: {
    status: UpdateTaskGqlStatus;
    recordUuid: TaskUUID;
    record: ApproveResultsInTaskRecordType;
    errors: ApproveResultsInTaskError;
  };
}

export interface ApproveResultsInTaskInput {
  uuid: TaskUUID;
  supportMark: TaskSupportMark;
  final?: boolean;
  feedback?: string;
  withoutClose?: boolean;
}

export interface ApproveResultsInTaskError {
  fullMessages: TaskGqlError;
}

const action = 'approveResultsInTask';

const scope = 'task';

const pluralScope = 'tasks';

function useApproveResultsInTask<ApproveResultsInTaskRecordType>({
  query,
  cacheKeys
}: ApproveResultsInTaskOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    ApproveResultsInTaskInput,
    ApproveResultsInTaskResponse<ApproveResultsInTaskRecordType>,
    ApproveResultsInTaskError,
    ApproveResultsInTaskRecordType
  >({ action, pluralScope, scope, query, cacheKeys });

  return {
    approveResultsInTaskData: updateQueryData,
    approveResultsInTaskError: updateQueryError,
    approveResultsInTaskLoading: updateQueryLoading,
    approveResultsInTaskErrorMessage: updateQueryErrorMessage,
    approveResultsInTask: updateQuery,
    approveResultsInTaskReset: updateQueryReset
  };
}

export default useApproveResultsInTask;
