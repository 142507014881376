import isEmpty from 'lodash/isEmpty';

import {
  MessageFileAttachmentsCreatedAt,
  MessageFileAttachmentsName,
  MessageFileAttachmentsSize,
  MessageSortType,
  MessageSortTypeTypes
} from '../../messagesTypes';

interface SortedAttachmentType {
  name: MessageFileAttachmentsName;
  createdAt?: MessageFileAttachmentsCreatedAt;
  size: MessageFileAttachmentsSize;
}

function sortMessageFileAttachments<
  AttachmentType extends SortedAttachmentType
>(
  attachments: AttachmentType[],
  sortType: MessageSortType = MessageSortTypeTypes.NAME_ASC
) {
  const sortedAttachments = isEmpty(attachments) ? [] : [...attachments];

  return sortedAttachments.sort((a, b) => {
    switch (sortType) {
      case MessageSortTypeTypes.NAME_ASC:
        return a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: 'base'
        });
      case MessageSortTypeTypes.NAME_DESC:
        return b.name.localeCompare(a.name, undefined, {
          numeric: true,
          sensitivity: 'base'
        });
      case MessageSortTypeTypes.CREATED_AT_DESC:
        if (!a.createdAt || !b.createdAt) {
          return 0;
        }

        return (
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      case MessageSortTypeTypes.CREATED_AT_ASC:
        if (!a.createdAt || !b.createdAt) {
          return 0;
        }

        return (
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
      case MessageSortTypeTypes.SIZE_ASC:
        return a.size - b.size;
      case MessageSortTypeTypes.SIZE_DESC:
        return b.size - a.size;
      default:
        return 0;
    }
  });
}

export default sortMessageFileAttachments;
