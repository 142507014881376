import {
  DownloadTaskSourceFilesGqlError,
  DownloadTaskSourceFilesGqlQuery,
  DownloadTaskSourceFilesGqlStatus,
  TaskDeviceNanoId,
  TaskNanoID,
  TaskUUID
} from '../../tasksTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface DownloadTaskSourceFilesOptions {
  query: DownloadTaskSourceFilesGqlQuery;
}

export interface DownloadTaskSourceFilesResponse<
  DownloadTaskSourceFilesRecordType
> {
  downloadTaskSourceFiles: {
    status: DownloadTaskSourceFilesGqlStatus;
    recordUuid: TaskUUID;
    recordNanoId: TaskNanoID;
    record: DownloadTaskSourceFilesRecordType;
    errors: DownloadTaskSourceFilesError;
  };
}

export interface DownloadTaskSourceFilesInput {
  uuid: TaskNanoID;
  deviceNanoId: TaskDeviceNanoId;
}

export interface DownloadTaskSourceFilesError {
  fullMessages: DownloadTaskSourceFilesGqlError;
}

const action = 'downloadTaskSourceFiles';

function useDownloadTaskSourceFiles<DownloadTaskSourceFilesRecordType>({
  query
}: DownloadTaskSourceFilesOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    DownloadTaskSourceFilesInput,
    DownloadTaskSourceFilesResponse<DownloadTaskSourceFilesRecordType>,
    DownloadTaskSourceFilesError,
    DownloadTaskSourceFilesRecordType
  >({ action, query });

  return {
    downloadTaskSourceFilesData: updateQueryData,
    downloadTaskSourceFilesError: updateQueryError,
    downloadTaskSourceFilesLoading: updateQueryLoading,
    downloadTaskSourceFilesErrorMessage: updateQueryErrorMessage,
    downloadTaskSourceFiles: updateQuery,
    downloadTaskSourceFilesReset: updateQueryReset
  };
}

export default useDownloadTaskSourceFiles;
