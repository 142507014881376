import {
  FileAttachmentID,
  FileAttachmentUUID
} from '../../../../fileAttachmentsTypes';

import {
  FETCH_SHOW_FILE_ATTACHMENT_WHITEBOARD_QUERY,
  FetchShowFileAttachmentWhiteboardQueryResponse
} from '../../../../queries/fetchFileAttachmentWhiteboard.query';

import { FileAttachmentCache } from '../../../../FileAttachmentCache';

import { useFileAttachment } from '../../../../hooks/useFileAttachment';

import { DateTimeHelper } from '../../../../../../helpers/DateTimeHelper';
import { NextPureLinkHelper } from '../../../../../../helpers/links/NextPureLinkHelper';
import { Translate } from '../../../../../../helpers/Translate';
import { UserPureLink } from '../../../../../common/helpers/UserPureLink';
import { getWhiteboardPath } from '../../../../../whiteboards/utils/getWhiteboardPath';

import { whiteboardsKeys, words } from '../../../../../../locales/keys';

interface FileAttachmentWhiteboardInfoProps {
  fileAttachmentId: FileAttachmentUUID | FileAttachmentID;
}
function FileAttachmentWhiteboardInfo({
  fileAttachmentId
}: FileAttachmentWhiteboardInfoProps) {
  const { fileAttachment } =
    useFileAttachment<FetchShowFileAttachmentWhiteboardQueryResponse>({
      cacheKey: FileAttachmentCache.showCacheKey(),
      query: FETCH_SHOW_FILE_ATTACHMENT_WHITEBOARD_QUERY,
      uuid: fileAttachmentId as FileAttachmentUUID
    });

  if (fileAttachment?.whiteboardKeyId) {
    const whiteboardPath = getWhiteboardPath(fileAttachment.whiteboardKeyId);

    return (
      <div>
        <Translate id={words.sentTo} />
        &nbsp;
        <NextPureLinkHelper
          className="underline"
          href={whiteboardPath}
          i18nText={whiteboardsKeys.singularLowerCase}
        />
        &nbsp;
        <Translate id={words.by} />
        &nbsp;
        <UserPureLink
          className="underline"
          i18nText={fileAttachment.whiteboardKeyUser?.fullName}
          user={fileAttachment.whiteboardKeyUser}
        />
        &nbsp;
        <Translate id={words.on} />
        &nbsp;
        <DateTimeHelper date={fileAttachment.whiteboardKeyAt} />
      </div>
    );
  }

  return <Translate id={whiteboardsKeys.sentToWhiteboard} />;
}

export default FileAttachmentWhiteboardInfo;
