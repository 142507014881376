import { gql } from 'graphql-request';

import { TaskNanoID, TaskUUID } from '../tasksTypes';

export interface SendResultsToClientInTaskQueryResponse {
  uuid: TaskUUID;
  nanoId: TaskNanoID;
}

export const SEND_RESULTS_TO_CLIENT_IN_TASK_QUERY = gql`
  mutation SendResultsToClientInTask($uuid: ID!) {
    sendResultsToClientInTask(input: { uuid: $uuid }) {
      recordUuid
      recordNanoId
      status
      record {
        uuid
        nanoId
      }
      errors {
        fullMessages
      }
    }
  }
`;
