import React, { useCallback } from 'react';

import { ClassName, I18nText } from '../../../../../types';

import { IconsEnum } from '../../../../../assets/icons/types';

import { TaskNanoID } from '../../../tasksTypes';

import {
  DOWNLOAD_TASK_RESULTS_QUERY,
  DownloadTaskResultsQueryResponse
} from '../../../queries/downloadTaskResults.query';

import { useDownloadNanoId } from '../../../../downloads/hooks/useDownloadNanoId';
import { useDownloadTaskResults } from '../../../hooks/useDownloadTaskResults';
import { useShowToastOnErrorChange } from '../../../../../common/hooks/useShowToastOnErrorChange';

import { PureTooltipIconButtonHelper } from '../../../../../helpers/buttons/PureTooltipIconButtonHelper';
import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

import { DownloadCache } from '../../../../downloads/DownloadCache';

interface DownloadTaskResultsButtonProps {
  taskNanoId: TaskNanoID;
  className?: ClassName;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  i18nText?: I18nText;
  tooltipI18nText?: I18nText;
  i18nTextClassName?: ClassName;
  tooltipPlacement?: TooltipPlacement;
}

function DownloadTaskResultsButton({
  taskNanoId,
  className,
  icon,
  iconClassName,
  i18nText,
  i18nTextClassName,
  tooltipI18nText,
  tooltipPlacement
}: DownloadTaskResultsButtonProps) {
  const { downloadNanoId } = useDownloadNanoId();

  const {
    downloadTaskResults,
    downloadTaskResultsErrorMessage,
    downloadTaskResultsLoading
  } = useDownloadTaskResults<DownloadTaskResultsQueryResponse>({
    query: DOWNLOAD_TASK_RESULTS_QUERY,
    cacheKeys: [DownloadCache.indexCacheKey()]
  });

  useShowToastOnErrorChange({
    error: downloadTaskResultsErrorMessage
  });

  const handleDownloadTaskResults = useCallback<() => void>(
    () =>
      downloadTaskResults({
        uuid: taskNanoId,
        deviceNanoId: downloadNanoId
      }),
    [taskNanoId, downloadNanoId, downloadTaskResults]
  );

  return (
    <PureTooltipIconButtonHelper
      className={
        className ||
        'py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0'
      }
      i18nText={i18nText}
      i18nTextClassName={i18nTextClassName}
      iconClassName={iconClassName || 'h-6 w-6'}
      icon={icon || IconsEnum.DOWNLOAD_OUTLINE}
      tooltipI18nText={tooltipI18nText}
      tooltipPlacement={tooltipPlacement}
      disabled={downloadTaskResultsLoading}
      onClick={handleDownloadTaskResults}
    />
  );
}

export default DownloadTaskResultsButton;
