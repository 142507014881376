import React, { ReactNode } from 'react';
import { Control } from 'react-hook-form';

import {
  ErrorMessage,
  FormID,
  I18nText,
  IsLoading
} from '../../../../../types';
import { TaskApproveResultsFields } from '../../../tasksTypes';
import { ApproveResultsInTaskFormData } from './ApproveResultsInTaskForm.types';

import { FinalResultField } from './components/FinalResultField';

import { CheckPermissions } from '../../../../../helpers/CheckPermissions';
import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { Form } from '../../../../../helpers/Form';
import { StarsRadioButtonsField } from '../../../../../helpers/FormFields/StarsRadioButtonsField';
import { Translate } from '../../../../../helpers/Translate';

import { TasksPermissions } from '../../../tasksConstants';

interface ApproveResultsInTaskFormProps {
  form: FormID;
  control: Control<ApproveResultsInTaskFormData>;
  isLoading: IsLoading;
  supportMarkValidationError: ErrorMessage;
  footer?: ReactNode;
  withResultFileAttachments?: boolean;
  approveResultsI18nText?: I18nText;
}

function ApproveResultsInTaskForm({
  form,
  control,
  isLoading,
  supportMarkValidationError,
  footer,
  withResultFileAttachments,
  approveResultsI18nText
}: ApproveResultsInTaskFormProps) {
  const starsRadioButtonsId = `${form}-star-radio-button`;

  return (
    <Form className="space-y-4" id={form}>
      {withResultFileAttachments ? (
        <CheckPermissions
          action={TasksPermissions.READ_APPROVE_RESULTS_IN_TASK_BUTTON}
        >
          {approveResultsI18nText ? (
            <div className="text-center">
              <Translate id={approveResultsI18nText} />
            </div>
          ) : null}
          <div className="flex justify-center">
            <StarsRadioButtonsField
              amount={5}
              name={TaskApproveResultsFields.SUPPORT_MARK}
              control={control}
              disabled={isLoading}
              iconClassName="w-6 h-6"
              id={starsRadioButtonsId}
            />
          </div>

          <CheckPermissions
            action={TasksPermissions.READ_APPROVE_RESULTS_IN_TASK_FINAL_RESULT}
          >
            <FinalResultField<ApproveResultsInTaskFormData>
              control={control}
              name={TaskApproveResultsFields.FINAL}
              disabled={isLoading}
            />
          </CheckPermissions>
        </CheckPermissions>
      ) : null}
      <AlertMessage i18nMessage={supportMarkValidationError} />
      {footer}
    </Form>
  );
}

export default ApproveResultsInTaskForm;
