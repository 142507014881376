import { gql } from 'graphql-request';

import {
  FileAttachmentFile,
  FileAttachmentID,
  FileAttachmentName,
  FileAttachmentNanoID,
  FileAttachmentUserFullName,
  FileAttachmentUserImageFile,
  FileAttachmentUserImageUUID,
  FileAttachmentUserNanoId,
  FileAttachmentUUID,
  FileAttachmentWhiteboardKeyAt,
  FileAttachmentWhiteboardKeyID
} from '../fileAttachmentsTypes';

export interface FetchShowFileAttachmentWhiteboardQueryResponse {
  id: FileAttachmentID;
  uuid: FileAttachmentUUID;
  nanoId: FileAttachmentNanoID;
  name: FileAttachmentName;
  file: FileAttachmentFile;
  whiteboardKeyId: FileAttachmentWhiteboardKeyID;
  whiteboardKeyAt: FileAttachmentWhiteboardKeyAt;
  whiteboardKeyUser: {
    nanoId: FileAttachmentUserNanoId;
    fullName: FileAttachmentUserFullName;
    image: {
      uuid: FileAttachmentUserImageUUID;
      file: FileAttachmentUserImageFile;
    };
  };
}

export const FETCH_SHOW_FILE_ATTACHMENT_WHITEBOARD_QUERY = gql`
  query ShowFileAttachmentWhiteboard($uuid: ID!) {
    fileAttachment(uuid: $uuid) {
      id
      uuid
      nanoId
      name
      file
      whiteboardKeyId
      whiteboardKeyAt
      whiteboardKeyUser {
        nanoId
        fullName
        image {
          uuid
          file
        }
      }
    }
  }
`;
