export class FileAttachmentCache {
  static showCacheKey() {
    return 'file-attachment';
  }

  static fileAttachmentsDropzoneHelper() {
    return 'file-attachments-dropzone-helper';
  }

  static fileAttachmentsInCreateMessageForm() {
    return 'file-attachments-in-create-message-form';
  }

  static imageFileAttachmentsInCreateMessageForm() {
    return 'image-file-attachments-in-create-message-form';
  }
}
